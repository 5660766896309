// be careful!
// generated file with Yii routes with webpack. Don't update lines here!
export const json = {
    "user-service\/register-intent-success": {
        "original_route": "user-service\/register-intent-success",
        "route": "user-service\/register-intent-success",
        "params": []
    },
    "keycloak\/contains": {
        "original_route": "keycloak\/contains",
        "route": "keycloak\/contains",
        "params": []
    },
    "user\/account\/request-password-reset-for-social-network": {
        "original_route": "acceso-red-social\/<socialNetwork>",
        "route": "acceso-red-social\/:socialNetwork",
        "params": [
            "socialNetwork"
        ]
    },
    "site\/error": {
        "original_route": "error",
        "route": "error",
        "params": []
    },
    "account\/login": {
        "original_route": "conectar",
        "route": "conectar",
        "params": []
    },
    "account\/end-process": {
        "original_route": "end-process",
        "route": "end-process",
        "params": []
    },
    "ser-fine\/show-detail": {
        "original_route": "GET ser\/denuncia\/<idSERFine:\\w+>\/detalle",
        "route": "ser\/denuncia\/:idSERFine\/detalle",
        "params": [
            "idSERFine"
        ]
    },
    "user\/user\/create": {
        "original_route": "POST usuario",
        "route": "usuario",
        "params": []
    },
    "user\/user\/update": {
        "original_route": "PUT usuario\/<id:\\d+>",
        "route": "usuario\/:id",
        "params": [
            "id"
        ]
    },
    "user\/user\/update-basic-location": {
        "original_route": "PUT localizacion-basica-usuario\/<id:\\d+>",
        "route": "localizacion-basica-usuario\/:id",
        "params": [
            "id"
        ]
    },
    "account\/logout": {
        "original_route": "desconectar",
        "route": "desconectar",
        "params": []
    },
    "user\/account\/request-delete-user": {
        "original_route": "PUT baja-usuario",
        "route": "baja-usuario",
        "params": []
    },
    "user\/account\/delete": {
        "original_route": "baja-usuario\/<token>",
        "route": "baja-usuario\/:token",
        "params": [
            "token"
        ]
    },
    "account\/edit": {
        "original_route": "mi-cuenta",
        "route": "mi-cuenta",
        "params": []
    },
    "account\/user": {
        "original_route": "mis-datos",
        "route": "mis-datos",
        "params": []
    },
    "account\/security": {
        "original_route": "seguridad",
        "route": "seguridad",
        "params": []
    },
    "user\/account\/social-login": {
        "original_route": "account\/social-login\/<social_network_name>",
        "route": "account\/social-login\/:social_network_name",
        "params": [
            "social_network_name"
        ]
    },
    "glovie\/vehicles": {
        "original_route": "mis-vehiculos",
        "route": "mis-vehiculos",
        "params": []
    },
    "glovie\/vehicles-page": {
        "original_route": "mis-vehiculos\/<page:\\d+>",
        "route": "mis-vehiculos\/:page",
        "params": [
            "page"
        ]
    },
    "glovie\/trailers": {
        "original_route": "mis-remolques",
        "route": "mis-remolques",
        "params": []
    },
    "glovie\/trailers-page": {
        "original_route": "mis-remolques\/<page:\\d+>",
        "route": "mis-remolques\/:page",
        "params": [
            "page"
        ]
    },
    "user\/account\/password-reset": {
        "original_route": "POST recuperar-contrasena",
        "route": "recuperar-contrasena",
        "params": []
    },
    "account\/request-password-reset": {
        "original_route": "recuperar-contrasena",
        "route": "recuperar-contrasena",
        "params": []
    },
    "user\/account\/reset-password-update": {
        "original_route": "POST cambiar-contrasena\/<token>",
        "route": "cambiar-contrasena\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/reset-password": {
        "original_route": "cambiar-contrasena\/<token>",
        "route": "cambiar-contrasena\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/email-update-request": {
        "original_route": "solicitar-cambio-email",
        "route": "solicitar-cambio-email",
        "params": []
    },
    "account\/update-email": {
        "original_route": "cambiar-email\/<token>\/<newEmail>",
        "route": "cambiar-email\/:token\/:newEmail",
        "params": [
            "token",
            "newEmail"
        ]
    },
    "user\/account\/confirm": {
        "original_route": "confirmar-cambio-email\/<code>",
        "route": "confirmar-cambio-email\/:code",
        "params": [
            "code"
        ]
    },
    "account\/register": {
        "original_route": "registro",
        "route": "registro",
        "params": []
    },
    "user\/user\/change-password": {
        "original_route": "cambiar-contrasena",
        "route": "cambiar-contrasena",
        "params": []
    },
    "site\/terms": {
        "original_route": "terminos-legales",
        "route": "terminos-legales",
        "params": []
    },
    "site\/terms-cookies": {
        "original_route": "politica-privacidad-cookies",
        "route": "politica-privacidad-cookies",
        "params": []
    },
    "site\/transparency": {
        "original_route": "transparencia",
        "route": "transparencia",
        "params": []
    },
    "account\/validate": {
        "original_route": "validar\/<token>",
        "route": "validar\/:token",
        "params": [
            "token"
        ]
    },
    "glovie\/pagatelia-order": {
        "original_route": "pedido\/viat\/<id:\\d+>",
        "route": "pedido\/viat\/:id",
        "params": [
            "id"
        ]
    },
    "order\/list": {
        "original_route": "pedidos",
        "route": "pedidos",
        "params": []
    },
    "order\/page": {
        "original_route": "pedidos\/<page:\\d+>",
        "route": "pedidos\/:page",
        "params": [
            "page"
        ]
    },
    "mobe\/list": {
        "original_route": "pedidos\/viat",
        "route": "pedidos\/viat",
        "params": []
    },
    "mobe\/page": {
        "original_route": "pedidos\/viat\/<page:\\d+>",
        "route": "pedidos\/viat\/:page",
        "params": [
            "page"
        ]
    },
    "toll\/list": {
        "original_route": "pedidos\/transitos",
        "route": "pedidos\/transitos",
        "params": []
    },
    "toll\/page": {
        "original_route": "pedidos\/transitos\/<page:\\d+>",
        "route": "pedidos\/transitos\/:page",
        "params": [
            "page"
        ]
    },
    "mobe\/update-vehicle": {
        "original_route": "PUT mobe\/<id:\\w+>\/vehiculo\/<vehicleId:\\d+>",
        "route": "mobe\/:id\/vehiculo\/:vehicleId",
        "params": [
            "id",
            "vehicleId"
        ]
    },
    "mobe\/unbind-vehicle": {
        "original_route": "PUT mobe\/<id:\\w*>\/desasignar-vehiculo",
        "route": "mobe\/:id\/desasignar-vehiculo",
        "params": [
            "id"
        ]
    },
    "mobe\/unassign": {
        "original_route": "PUT mobe\/<id:\\w*>\/baja",
        "route": "mobe\/:id\/baja",
        "params": [
            "id"
        ]
    },
    "mobe\/search": {
        "original_route": "GET mobe\/search",
        "route": "mobe\/search",
        "params": []
    },
    "toll\/search": {
        "original_route": "GET transito\/buscar",
        "route": "transito\/buscar",
        "params": []
    },
    "mobe\/update-wallet": {
        "original_route": "PUT mobe\/<idMobe:\\w+>\/monedero\/<idWallet:\\d+>",
        "route": "mobe\/:idMobe\/monedero\/:idWallet",
        "params": [
            "idMobe",
            "idWallet"
        ]
    },
    "site\/landing-ora": {
        "original_route": "GET pago-movil-estacionamiento-regulado",
        "route": "pago-movil-estacionamiento-regulado",
        "params": []
    },
    "ser-ticket\/show-detail": {
        "original_route": "GET ticket\/<idSERTicket:\\w+>\/detalle",
        "route": "ticket\/:idSERTicket\/detalle",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/download-pdf": {
        "original_route": "GET ticket\/<idSERTicket:\\w+>.pdf",
        "route": "ticket\/:idSERTicket.pdf",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/send-email": {
        "original_route": "GET ticket\/<idSERTicket:\\w+>\/enviar-email",
        "route": "ticket\/:idSERTicket\/enviar-email",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/pay": {
        "original_route": "aparcar-en-<slug>\/sacar-ticket",
        "route": "aparcar-en-:slug\/sacar-ticket",
        "params": [
            "slug"
        ]
    },
    "ser-ticket\/create": {
        "original_route": "POST tickets-ser\/sacar-ticket",
        "route": "tickets-ser\/sacar-ticket",
        "params": []
    },
    "ser-ticket\/unpark": {
        "original_route": "POST ticket\/<id>\/finalizar-ticket",
        "route": "ticket\/:id\/finalizar-ticket",
        "params": [
            "id"
        ]
    },
    "ser-ticket\/check-unpark": {
        "original_route": "GET ticket\/<id>\/finalizar-ticket",
        "route": "ticket\/:id\/finalizar-ticket",
        "params": [
            "id"
        ]
    },
    "ser-ticket\/new": {
        "original_route": "ticket\/<id>\/nuevo",
        "route": "ticket\/:id\/nuevo",
        "params": [
            "id"
        ]
    },
    "wallet\/payment-method": {
        "original_route": "monedero\/forma-de-pago",
        "route": "monedero\/forma-de-pago",
        "params": []
    },
    "wallet\/my-wallet": {
        "original_route": "monedero",
        "route": "monedero",
        "params": []
    },
    "wallet\/show": {
        "original_route": "monedero\/<idWallet:\\d+>\/ver",
        "route": "monedero\/:idWallet\/ver",
        "params": [
            "idWallet"
        ]
    },
    "wallet\/data": {
        "original_route": "monedero\/<idWallet:\\d+>\/datos",
        "route": "monedero\/:idWallet\/datos",
        "params": [
            "idWallet"
        ]
    },
    "wallet\/beneficiaries": {
        "original_route": "monedero\/<idWallet:\\d+>\/beneficiarios",
        "route": "monedero\/:idWallet\/beneficiarios",
        "params": [
            "idWallet"
        ]
    },
    "glovie\/wallet": {
        "original_route": "monederos",
        "route": "monederos",
        "params": []
    },
    "wallet\/create-deposit": {
        "original_route": "POST monedero\/recarga",
        "route": "monedero\/recarga",
        "params": []
    },
    "wallet\/confirm-payment-intent": {
        "original_route": "POST monedero\/confirm-payment-intent",
        "route": "monedero\/confirm-payment-intent",
        "params": []
    },
    "credit-card\/create": {
        "original_route": "POST tarjeta-credito",
        "route": "tarjeta-credito",
        "params": []
    },
    "wallet\/associate-credit-card": {
        "original_route": "POST monedero\/<idWallet:\\d+>\/asociar-tarjeta\/<idCreditCard:\\d+>",
        "route": "monedero\/:idWallet\/asociar-tarjeta\/:idCreditCard",
        "params": [
            "idWallet",
            "idCreditCard"
        ]
    },
    "wallet\/pay-debt": {
        "original_route": "PUT monedero\/saldar-deuda",
        "route": "monedero\/saldar-deuda",
        "params": []
    },
    "invoice\/pdf": {
        "original_route": "GET facturacion\/facturas\/<invoiceUid>.pdf",
        "route": "facturacion\/facturas\/:invoiceUid.pdf",
        "params": [
            "invoiceUid"
        ]
    },
    "invoice\/view-xlsx": {
        "original_route": "GET descarga-facturacion\/<invoiceUid>.xlsx",
        "route": "descarga-facturacion\/:invoiceUid.xlsx",
        "params": [
            "invoiceUid"
        ]
    },
    "invoice\/list": {
        "original_route": "GET facturacion\/facturas",
        "route": "facturacion\/facturas",
        "params": []
    },
    "invoice\/page": {
        "original_route": "GET facturacion\/facturas\/<page:\\d+>",
        "route": "facturacion\/facturas\/:page",
        "params": [
            "page"
        ]
    },
    "invoice\/update": {
        "original_route": "PUT facturacion\/datos",
        "route": "facturacion\/datos",
        "params": []
    },
    "parking\/list": {
        "original_route": "GET buscar-parking",
        "route": "buscar-parking",
        "params": []
    },
    "parking\/list-aeroparking": {
        "original_route": "GET buscar-parking-aeropuerto",
        "route": "buscar-parking-aeropuerto",
        "params": []
    },
    "parking\/list-season-ticket": {
        "original_route": "GET buscar-abono",
        "route": "buscar-abono",
        "params": []
    },
    "parking\/search-season-ticket": {
        "original_route": "POST buscar-abono",
        "route": "buscar-abono",
        "params": []
    },
    "booking\/process": {
        "original_route": "GET confirmar-reserva\/<idPrebooking:\\d+>",
        "route": "confirmar-reserva\/:idPrebooking",
        "params": [
            "idPrebooking"
        ]
    },
    "vehicle-inspection-booking\/process": {
        "original_route": "GET confirmar-reserva-itv",
        "route": "confirmar-reserva-itv",
        "params": []
    },
    "glovie\/list-actives": {
        "original_route": "GET guantera\/actuales",
        "route": "guantera\/actuales",
        "params": []
    },
    "glovie\/list-inactives": {
        "original_route": "GET guantera\/pasados\/<page:\\d+>",
        "route": "guantera\/pasados\/:page",
        "params": [
            "page"
        ]
    },
    "booking\/show": {
        "original_route": "GET reserva\/<idBooking:\\d+>\/ver",
        "route": "reserva\/:idBooking\/ver",
        "params": [
            "idBooking"
        ]
    },
    "booking\/new": {
        "original_route": "GET reserva\/<idBooking:\\d+>\/nueva",
        "route": "reserva\/:idBooking\/nueva",
        "params": [
            "idBooking"
        ]
    },
    "booking\/cancel": {
        "original_route": "PUT reserva\/cancelar\/<idBooking:\\d+>",
        "route": "reserva\/cancelar\/:idBooking",
        "params": [
            "idBooking"
        ]
    },
    "vehicle-brand\/vehicle-models": {
        "original_route": "GET vehiculo-marca\/<brandId:\\d+>\/modelos",
        "route": "vehiculo-marca\/:brandId\/modelos",
        "params": [
            "brandId"
        ]
    },
    "vehicle\/index": {
        "original_route": "GET vehiculo",
        "route": "vehiculo",
        "params": []
    },
    "vehicle\/view": {
        "original_route": "GET vehiculo\/<vehicleId:\\d+>",
        "route": "vehiculo\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "vehicle\/delete": {
        "original_route": "DELETE vehiculo\/<vehicleId:\\d+>",
        "route": "vehiculo\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "vehicle\/create": {
        "original_route": "POST vehiculo",
        "route": "vehiculo",
        "params": []
    },
    "vehicle\/update": {
        "original_route": "PUT vehiculo\/<vehicleId:\\d+>",
        "route": "vehiculo\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "season-ticket\/contract": {
        "original_route": "GET abono\/<seasonTicketId:\\d+>\/contratar",
        "route": "abono\/:seasonTicketId\/contratar",
        "params": [
            "seasonTicketId"
        ]
    },
    "season-ticket-contract\/show-detail": {
        "original_route": "GET contratos\/abono\/<id:\\d+>\/detalle",
        "route": "contratos\/abono\/:id\/detalle",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/show": {
        "original_route": "GET contratos\/abono\/<id:\\d+>\/ver",
        "route": "contratos\/abono\/:id\/ver",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/new": {
        "original_route": "GET contratos\/abono\/<id:\\d+>\/nuevo",
        "route": "contratos\/abono\/:id\/nuevo",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/create": {
        "original_route": "POST abono",
        "route": "abono",
        "params": []
    },
    "season-ticket-contract\/update-vehicle": {
        "original_route": "PUT abono\/<contractId:\\d+>\/vehicle\/<vehicleId:\\d+>",
        "route": "abono\/:contractId\/vehicle\/:vehicleId",
        "params": [
            "contractId",
            "vehicleId"
        ]
    },
    "site\/operators": {
        "original_route": "operadores",
        "route": "operadores",
        "params": []
    },
    "glovie\/preferences": {
        "original_route": "GET preferencias",
        "route": "preferencias",
        "params": []
    },
    "user-preferences\/update": {
        "original_route": "PUT preferencias\/<id:\\d+>",
        "route": "preferencias\/:id",
        "params": [
            "id"
        ]
    },
    "glovie\/privacy": {
        "original_route": "GET privacidad",
        "route": "privacidad",
        "params": []
    },
    "user\/privacy\/create-update": {
        "original_route": "PUT privacidad",
        "route": "privacidad",
        "params": []
    },
    "wallet-preferences\/index": {
        "original_route": "GET preferencias-monedero",
        "route": "preferencias-monedero",
        "params": []
    },
    "wallet-preferences\/update": {
        "original_route": "PUT preferencias-monedero\/<id:\\d+>",
        "route": "preferencias-monedero\/:id",
        "params": [
            "id"
        ]
    },
    "ser-zone\/show": {
        "original_route": "GET zona\/<zoneId>\/vehiculo\/<vehicleId:\\d+>",
        "route": "zona\/:zoneId\/vehiculo\/:vehicleId",
        "params": [
            "zoneId",
            "vehicleId"
        ]
    },
    "glovie\/fines": {
        "original_route": "GET anular-denuncia",
        "route": "anular-denuncia",
        "params": []
    },
    "ser-fine\/check": {
        "original_route": "POST denuncia\/check",
        "route": "denuncia\/check",
        "params": []
    },
    "shop\/shop\/invoice": {
        "original_route": "factura\/<uid>.pdf",
        "route": "factura\/:uid.pdf",
        "params": [
            "uid"
        ]
    },
    "user\/account\/unsubscribe-from-commercials": {
        "original_route": "baja-correo-comercial\/<email>",
        "route": "baja-correo-comercial\/:email",
        "params": [
            "email"
        ]
    },
    "viat-shop\/redirect": {
        "original_route": "tienda\/productos",
        "route": "tienda\/productos",
        "params": []
    },
    "viat-shop\/detail": {
        "original_route": "tienda\/pedido\/<id>",
        "route": "tienda\/pedido\/:id",
        "params": [
            "id"
        ]
    },
    "viat-shop\/shop": {
        "original_route": "GET tienda\/confirmar-pedido\/<id>",
        "route": "tienda\/confirmar-pedido\/:id",
        "params": [
            "id"
        ]
    },
    "viat-shop\/new": {
        "original_route": "GET tienda\/pedido\/<id>\/nuevo",
        "route": "tienda\/pedido\/:id\/nuevo",
        "params": [
            "id"
        ]
    },
    "qr\/view": {
        "original_route": "qr\/<value>",
        "route": "qr\/:value",
        "params": [
            "value"
        ]
    },
    "site\/viat": {
        "original_route": "viat",
        "route": "viat",
        "params": []
    },
    "site\/viat-parkings": {
        "original_route": "viat\/parkings",
        "route": "viat\/parkings",
        "params": []
    },
    "site\/viat-portugal": {
        "original_route": "viat\/autopistas-portugal",
        "route": "viat\/autopistas-portugal",
        "params": []
    },
    "site\/viat-discounts": {
        "original_route": "viat\/descuentos",
        "route": "viat\/descuentos",
        "params": []
    },
    "site\/viat-how-it-works": {
        "original_route": "viat\/como-funciona",
        "route": "viat\/como-funciona",
        "params": []
    },
    "site\/landing-how-to-park": {
        "original_route": "como-aparcar-parkings",
        "route": "como-aparcar-parkings",
        "params": []
    },
    "site\/landing-how-to-park-airport-parkings": {
        "original_route": "parking-aeropuerto",
        "route": "parking-aeropuerto",
        "params": []
    },
    "site\/landing-merchant": {
        "original_route": "comerciantes",
        "route": "comerciantes",
        "params": []
    },
    "site\/landing-parking-sharing": {
        "original_route": "alquilar-garaje",
        "route": "alquilar-garaje",
        "params": []
    },
    "site\/landing-merchant-how-it-works": {
        "original_route": "canjear-cupones",
        "route": "canjear-cupones",
        "params": []
    },
    "airport\/show": {
        "original_route": "aparcar-en-aeropuerto-<slug>\/<id:\\d+>",
        "route": "aparcar-en-aeropuerto-:slug\/:id",
        "params": [
            "slug",
            "id"
        ]
    },
    "town\/show": {
        "original_route": "aparcar-en-<slug>",
        "route": "aparcar-en-:slug",
        "params": [
            "slug"
        ]
    },
    "user-preferences\/auto-deposit": {
        "original_route": "deposito",
        "route": "deposito",
        "params": []
    },
    "workshop-booking\/new": {
        "original_route": "reserva-taller\/<idBooking:\\d+>\/nueva",
        "route": "reserva-taller\/:idBooking\/nueva",
        "params": [
            "idBooking"
        ]
    },
    "workshop-booking\/selection": {
        "original_route": "reservar-taller\/<idProduct:\\d+>",
        "route": "reservar-taller\/:idProduct",
        "params": [
            "idProduct"
        ]
    },
    "workshop-booking\/process": {
        "original_route": "confirmar-reserva-taller\/<idBooking:\\d+>",
        "route": "confirmar-reserva-taller\/:idBooking",
        "params": [
            "idBooking"
        ]
    },
    "glovie\/show-workshop-booking": {
        "original_route": "reserva-taller\/<idWorkshopBooking:\\d+>\/ver",
        "route": "reserva-taller\/:idWorkshopBooking\/ver",
        "params": [
            "idWorkshopBooking"
        ]
    },
    "site\/landing-workshop": {
        "original_route": "talleres",
        "route": "talleres",
        "params": []
    },
    "vehicle-inspection-station\/show": {
        "original_route": "itv\/<slug>\/<id:\\d+>",
        "route": "itv\/:slug\/:id",
        "params": [
            "slug",
            "id"
        ]
    },
    "site\/landing-vehicle-inspection": {
        "original_route": "itv",
        "route": "itv",
        "params": []
    },
    "site\/landing-electric-charge": {
        "original_route": "carga-electrica",
        "route": "carga-electrica",
        "params": []
    },
    "vehicle-inspection-station\/list": {
        "original_route": "buscar-itv",
        "route": "buscar-itv",
        "params": []
    },
    "glovie\/show-itv-booking": {
        "original_route": "GET reserva-itv\/<idBooking:\\d+>\/ver",
        "route": "reserva-itv\/:idBooking\/ver",
        "params": [
            "idBooking"
        ]
    },
    "glovie\/show-rotation": {
        "original_route": "GET rotacion\/<idRotation:\\d+>\/ver",
        "route": "rotacion\/:idRotation\/ver",
        "params": [
            "idRotation"
        ]
    },
    "glovie\/show-itv-booking-new": {
        "original_route": "GET reserva-itv\/<idBooking:\\d+>\/nueva",
        "route": "reserva-itv\/:idBooking\/nueva",
        "params": [
            "idBooking"
        ]
    },
    "parking\/show-deep-link": {
        "original_route": "GET parking-detail",
        "route": "parking-detail",
        "params": []
    },
    "parking\/show": {
        "original_route": "GET <province>\/<slug>\/<idParking:\\d+>",
        "route": ":province\/:slug\/:idParking",
        "params": [
            "province",
            "slug",
            "idParking"
        ]
    },
    "site\/home-redirect": {
        "original_route": "esp",
        "route": "esp",
        "params": []
    },
    "wallet\/list": {
        "original_route": "GET monedero\/lista",
        "route": "monedero\/lista",
        "params": []
    },
    "booking\/pay-with-wallet": {
        "original_route": "PUT pagar-reserva\/<idBooking:\\d+>\/monedero\/<idWallet:\\d+>",
        "route": "pagar-reserva\/:idBooking\/monedero\/:idWallet",
        "params": [
            "idBooking",
            "idWallet"
        ]
    },
    "season-ticket-contract\/create-and-pay-with-wallet": {
        "original_route": "POST pagar-abono\/monedero\/<idWallet:\\d+>",
        "route": "pagar-abono\/monedero\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "ser-ticket\/create-and-pay-with-wallet": {
        "original_route": "POST pagar-ticket\/monedero\/<idWallet:\\d+>",
        "route": "pagar-ticket\/monedero\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "ser-fine\/pay-with-wallet": {
        "original_route": "POST pagar-denuncia\/monedero\/<idWallet:\\d+>",
        "route": "pagar-denuncia\/monedero\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "merchant\/index": {
        "original_route": "comerciantes\/mi-cuenta",
        "route": "comerciantes\/mi-cuenta",
        "params": []
    },
    "merchant\/shop": {
        "original_route": "comerciantes\/tienda",
        "route": "comerciantes\/tienda",
        "params": []
    },
    "merchant\/shop-step": {
        "original_route": "comerciantes\/tienda\/paso<id:\\d+>",
        "route": "comerciantes\/tienda\/paso:id",
        "params": [
            "id"
        ]
    },
    "coupon-order\/pdf": {
        "original_route": "comerciantes\/pedido\/<id:\\d+>.pdf",
        "route": "comerciantes\/pedido\/:id.pdf",
        "params": [
            "id"
        ]
    },
    "merchant\/order": {
        "original_route": "comerciantes\/pedido\/<id:\\d+>\/detalle",
        "route": "comerciantes\/pedido\/:id\/detalle",
        "params": [
            "id"
        ]
    },
    "merchant\/sample-coupon": {
        "original_route": "merchant\/sample-coupon",
        "route": "merchant\/sample-coupon",
        "params": []
    },
    "site\/landing-enterprise": {
        "original_route": "empresas",
        "route": "empresas",
        "params": []
    },
    "kiosk\/reset-password": {
        "original_route": "kiosko\/recuperar-contrasena\/<token>",
        "route": "kiosko\/recuperar-contrasena\/:token",
        "params": [
            "token"
        ]
    },
    "on-boarding\/redirect": {
        "original_route": "on-boarding-ok",
        "route": "on-boarding-ok",
        "params": []
    },
    "site\/landing-petrol-station": {
        "original_route": "gasolineras",
        "route": "gasolineras",
        "params": []
    },
    "site\/landing-parkings": {
        "original_route": "parkings",
        "route": "parkings",
        "params": []
    },
    "poi\/show": {
        "original_route": "aparcar-en-<townSlug>\/parking-<poiSlug>",
        "route": "aparcar-en-:townSlug\/parking-:poiSlug",
        "params": [
            "townSlug",
            "poiSlug"
        ]
    },
    "poi\/show-events": {
        "original_route": "eventos-en-<poiId>\/<month:\\d+>",
        "route": "eventos-en-:poiId\/:month",
        "params": [
            "poiId",
            "month"
        ]
    },
    "poi\/show-vehicle-inspection-poi": {
        "original_route": "itv\/cita-itv-<poiSlug>",
        "route": "itv\/cita-itv-:poiSlug",
        "params": [
            "poiSlug"
        ]
    },
    "offered-parking-pass\/contract": {
        "original_route": "GET parking\/<idParking:\\d+>\/bono-pases\/<id:\\d+>",
        "route": "parking\/:idParking\/bono-pases\/:id",
        "params": [
            "idParking",
            "id"
        ]
    },
    "booked-parking-pass\/new": {
        "original_route": "bono-pases\/<id:\\d+>\/nuevo",
        "route": "bono-pases\/:id\/nuevo",
        "params": [
            "id"
        ]
    },
    "booked-parking-pass\/show": {
        "original_route": "bono-pases\/<id:\\d+>\/ver",
        "route": "bono-pases\/:id\/ver",
        "params": [
            "id"
        ]
    },
    "mas-movil\/refuse-lot-confirm": {
        "original_route": "POST masmovil\/rechazar-plaza\/<token>",
        "route": "masmovil\/rechazar-plaza\/:token",
        "params": [
            "token"
        ]
    },
    "mas-movil\/refuse-lot": {
        "original_route": "masmovil\/rechazar-plaza\/<token>",
        "route": "masmovil\/rechazar-plaza\/:token",
        "params": [
            "token"
        ]
    },
    "charging-point\/list": {
        "original_route": "buscar-estacion-carga-electrica",
        "route": "buscar-estacion-carga-electrica",
        "params": []
    },
    "glovie\/show-charging-point-booking": {
        "original_route": "GET reserva-carga-electrica\/<idBooking:\\d+>\/ver",
        "route": "reserva-carga-electrica\/:idBooking\/ver",
        "params": [
            "idBooking"
        ]
    },
    "driver-plus\/landing-fines": {
        "original_route": "conductor-plus\/multas-no-notificadas",
        "route": "conductor-plus\/multas-no-notificadas",
        "params": []
    },
    "driver-plus\/contract": {
        "original_route": "conductor-plus\/contratar",
        "route": "conductor-plus\/contratar",
        "params": []
    },
    "glovie\/driver-plus-subscriptions": {
        "original_route": "conductor-plus\/suscripciones",
        "route": "conductor-plus\/suscripciones",
        "params": []
    },
    "glovie\/driver-plus-fines": {
        "original_route": "conductor-plus\/multas",
        "route": "conductor-plus\/multas",
        "params": []
    },
    "api\/wallet-corporate\/download-file": {
        "original_route": "GET wallets\/<walletId:\\d+>\/download-movements-csv\/<file>",
        "route": "wallets\/:walletId\/download-movements-csv\/:file",
        "params": [
            "walletId",
            "file"
        ]
    },
    "wallet-corporate-plates-whitelist\/download": {
        "original_route": "plates-whitelist\/<walletId:\\d+>\/download",
        "route": "plates-whitelist\/:walletId\/download",
        "params": [
            "walletId"
        ]
    },
    "rental-car\/show": {
        "original_route": "alquilar-coches",
        "route": "alquilar-coches",
        "params": []
    },
    "rental-car\/contract": {
        "original_route": "finalizar-alquilar-coche",
        "route": "finalizar-alquilar-coche",
        "params": []
    },
    "rental-car\/new": {
        "original_route": "alquilar-coches\/<id:\\d+>\/nuevo",
        "route": "alquilar-coches\/:id\/nuevo",
        "params": [
            "id"
        ]
    },
    "rental-car\/detail": {
        "original_route": "alquilar-coches\/<id:\\d+>\/detalle",
        "route": "alquilar-coches\/:id\/detalle",
        "params": [
            "id"
        ]
    },
    "rental-car\/search": {
        "original_route": "alquilar-coches\/buscar",
        "route": "alquilar-coches\/buscar",
        "params": []
    },
    "rental-car\/un-born": {
        "original_route": "alquilar-coches\/ver\/<unbornId:\\d+>\/<uuid>",
        "route": "alquilar-coches\/ver\/:unbornId\/:uuid",
        "params": [
            "unbornId",
            "uuid"
        ]
    },
    "rental-car\/un-born-share": {
        "original_route": "alquilar-coches\/share\/<unbornId:\\d+>\/<uuid>",
        "route": "alquilar-coches\/share\/:unbornId\/:uuid",
        "params": [
            "unbornId",
            "uuid"
        ]
    },
    "site\/index": {
        "original_route": "",
        "route": "",
        "params": []
    },
    "esp\/sitemap\/lang": {
        "original_route": "es\/sitemap.xml",
        "route": "es\/sitemap.xml",
        "params": []
    },
    "account\/on-boarding": {
        "original_route": "completar-perfil",
        "route": "completar-perfil",
        "params": []
    }
};